<template>
  <div class="w-full flex flex-col gap-6">
    <div v-if="otpModalOpen">
      <CodeVerification
        title="Code Validation"
        description="Enter in the 6-digit code from your authenticator app. If you're not using an authenticator app, click the link below to send the code to your email."
        v-model="otpValue"
        :errors="otpError"
        @submit="otpLogin"
        @resend="sendTwoFactorAuthEmail"
      />
    </div>

    <div v-else class="flex flex-col gap-6">
      <div class="flex flex-row items-center gap-3">
        <Logo :loading="pending" variant="square" size="h-6" />
        <h1 class="text-2xl font-bold">Sign In</h1>
      </div>
      <p class="text-red-600">{{ form.error }}</p>

      <UForm
        :state="form.data"
        @submit="performLogin"
        class="w-full flex flex-col gap-4"
      >
        <FormGroup
          label="Email or Username"
          name="email"
          size="sm"
          class="relative"
        >
          <FormInput
            v-model="form.data.email"
            label="Email"
            placeholder="mail@provider.com"
            :required="true"
          />
        </FormGroup>

        <FormGroup label="Password" name="password" size="sm" class="relative">
          <FormPasswordInput
            v-model="form.data.password"
            placeholder="Your password"
          />
        </FormGroup>

        <div class="text-right pb-2">
          <NuxtLink to="/forgot-password">Forgot Password?</NuxtLink>
        </div>

        <Button
          theme="primary"
          :disabled="!form.data.email && !form.data.password"
          type="submit"
          class="mt-4"
          block
        >
          Continue
        </Button>
      </UForm>

      <div class="flex flex-col gap-2">
        <Button theme="secondary" size="xl" block @click="loginWithGoogle">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
          >
            <path
              d="M4.04607 10.1023L3.48911 12.1815L1.45344 12.2246C0.845075 11.0962 0.5 9.80515 0.5 8.43323C0.5 7.10658 0.822637 5.85554 1.39453 4.75397H1.39497L3.20729 5.08623L4.00119 6.88767C3.83503 7.37209 3.74446 7.89211 3.74446 8.43323C3.74452 9.0205 3.8509 9.58317 4.04607 10.1023Z"
              fill="#FBBB00"
            />
            <path
              d="M16.3604 6.93854C16.4523 7.42249 16.5002 7.92229 16.5002 8.43309C16.5002 9.00586 16.44 9.56457 16.3252 10.1035C15.9358 11.9374 14.9182 13.5388 13.5085 14.672L13.508 14.6716L11.2253 14.5551L10.9022 12.5383C11.8376 11.9897 12.5687 11.1312 12.9537 10.1035H8.67578V6.93854H13.0162H16.3604Z"
              fill="#518EF8"
            />
            <path
              d="M13.509 14.6713L13.5095 14.6718C12.1385 15.7738 10.3968 16.4332 8.50095 16.4332C5.45425 16.4332 2.80537 14.7303 1.4541 12.2242L4.04673 10.102C4.72235 11.9051 6.46175 13.1887 8.50095 13.1887C9.37745 13.1887 10.1986 12.9517 10.9032 12.5381L13.509 14.6713Z"
              fill="#28B446"
            />
            <path
              d="M13.6064 2.2745L11.0146 4.39632C10.2854 3.94049 9.42334 3.67717 8.4998 3.67717C6.41444 3.67717 4.6425 5.01963 4.00073 6.88741L1.39448 4.75371H1.39404C2.72553 2.18659 5.40781 0.432678 8.4998 0.432678C10.441 0.432678 12.2208 1.12414 13.6064 2.2745Z"
              fill="#F14336"
            />
          </svg>
          <span class="text-cool-600 dark:text-white">Sign in with Google</span>
        </Button>
        <Button theme="secondary" size="xl" block @click="loginWithX">
          <font-awesome-icon
            icon="fa-brands fa-x-twitter"
            class="text-black dark:text-white"
          />
          <span class="text-cool-600 dark:text-white">Sign in with X</span>
        </Button>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-vue-v3"

  const nuxtApp = useNuxtApp()
  const userStore = useUserStore()
  const route = useRoute()
  const config = useRuntimeConfig()
  const toast = useToast()

  const { data: fingerprintData, getData: getFingerprintData } = useVisitorData(
    { extendedResult: true },
    { immediate: true },
  )

  watch(fingerprintData, (currentData) => {
    if (process.client) {
      if (currentData) {
        nextTick(() => {
          $api(`${config.public.API_URL}/api/fingerprint/attach`, {
            method: "POST",
            body: {
              username: userStore.loggedIn ? userStore.username : null,
              visitor_id: currentData.visitorId,
            },
          })
        })
      }
    }
  })

  const otpModalOpen = ref(false)
  const otpValue = ref(Array(6).fill(""))

  const form = reactive({
    data: {
      email: null,
      password: null,
      code: null,
    },
    error: null,
    pending: false,
    otpOpen: false,
  })

  const emit = defineEmits(["logged-in"])

  const loginWithGoogle = async () => {
    const uri = await $fetch(
      `${config.public.API_URL}/api/oauth/google/redirect_uri`,
    )
    window.open(uri, "_self")
  }

  const loginWithX = async () => {
    const uri = await $fetch(
      `${config.public.API_URL}/api/oauth/x/redirect_uri`,
    )
    window.open(uri, "_self")
  }

  // TODO: Re-enable after the move to favoritely.com is done.
  // https://github.com/sscgroupllc/favoritely/issues/663
  const trackFingerprints = computed(() => {
    return false
    // return (
    //   config.public.API_URL == "staging" ||
    //   config.public.API_URL == "production"
    // )
  })

  const performLogin = async () => {
    try {
      form.error = null
      form.pending = true
      const { success, waitingForOtp } = await userStore.login(
        form.data.email,
        form.data.password,
      )

      if (success && waitingForOtp) {
        otpModalOpen.value = true
      } else {
        toast.add({ title: "Logged in. Welcome!" })
        emit("logged-in")
        const redirectBackTo = route.query.redirectBackTo || "/"
        useLoginTracking()
        if (trackFingerprints.value) {
          getFingerprintData({
            linkedId: userStore.loggedIn ? userStore.userId : null,
            tag: {
              userAction: "login",
            },
          })
        }
        await navigateTo(redirectBackTo)
      }
    } catch (error) {
      if (error.message) form.error = error.message
    } finally {
      form.pending = false
    }
  }

  const otpError = ref(null)
  const otpLogin = async () => {
    try {
      form.error = null
      form.pending = true

      const { success } = await userStore.authenticate(
        form.data.email,
        otpValue.value.join(""),
      )

      if (success) {
        const redirectBackTo = route.query.redirectBackTo || "/"
        useLoginTracking()
        if (trackFingerprints.value) {
          getFingerprintData({
            linkedId: userStore.loggedIn ? userStore.userId : null,
            tag: {
              userAction: "login",
            },
          })
        }
        await navigateTo(redirectBackTo)
        toast.add({ title: "Logged in. Welcome!" })
        emit("logged-in")
      } else {
        otpError.value = "There was a problem with your verification code"
        toast.add({
          title: "There was a problem with your verification code",
          color: "red",
        })
      }
    } catch (error) {
      if (error.message) form.error = error.message
      otpError.value = "There was a problem with your verification code"
      toast.add({
        title: "There was a problem with your verification code",
        color: "red",
      })
    } finally {
      form.pending = false
    }
  }

  const sendTwoFactorAuthEmail = async () => {
    const config = useRuntimeConfig()
    await $api(config.public.API_URL + `/api/send_two_factor_auth_email`, {
      method: "POST",
      body: {
        email: form.data.email,
      },
    })

    toast.add({ title: "A code was sent to your email address." })
  }
</script>
